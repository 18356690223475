import ColorPicker from './ColorPicker';
export default ColorPicker;

import colorKit from './colorKit/index';
export { colorKit };

export { Panel1 } from './components/Panels/Panel1';
export { Panel2 } from './components/Panels/Panel2';
export { Panel3 } from './components/Panels/Panel3';
export { Panel4 } from './components/Panels/Panel4';
export { Panel5 } from './components/Panels/Panel5';

export { HueSlider } from './components/Sliders/HSB/HueSlider';
export { SaturationSlider } from './components/Sliders/HSB/SaturationSlider';
export { BrightnessSlider } from './components/Sliders/HSB/BrightnessSlider';

export { OpacitySlider } from './components/Sliders/OpacitySlider';
export { HueCircular } from './components/Sliders/HueCircular';

export { RedSlider } from './components/Sliders/RGB/RedSlider';
export { GreenSlider } from './components/Sliders/RGB/GreenSlider';
export { BlueSlider } from './components/Sliders/RGB/BlueSlider';

export { Preview } from './components/Preview';
export { PreviewText } from './components/PreviewText';

export { InputWidget } from './components/InputWidget/InputWidget';

export { Swatches } from './components/Swatches';

export * from './types';
